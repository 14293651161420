import React from "react";
import { useState, useEffect, useReducer, useRef } from "react";
import { DndContext } from "@dnd-kit/core";
import { motion, LayoutGroup, AnimatePresence } from "framer-motion";

import "../output.css";

import { Draggable } from "./Draggable";

import { SpellCard } from "./SpellCard";
import { Form } from "./Form";
import { INITIAL_STATE, formReducer, SpellCardInfo } from "./formReducer";
import { SpellBook } from "./SpellBook";
import { FORM_ACTIONS } from "./formActions";
import {
  initDB,
  addImage,
  deleteImage,
  deleteAll,
  copyImage,
  getImage,
  setHasUpdate,
  checkHasUpdate,
} from "./imageDB";
import { InfoModal } from "./InfoModal";
import { Button, ButtonType } from "./Button";

function SpellCardWizard() {
  // * Variables ---------------------------------------------
  // State for input fields from formReducer
  const [fieldStates, dispatch] = useReducer(formReducer, INITIAL_STATE);

  // State for CardCreator menu
  const [isCollapse, setCollapse] = useState<boolean>(false);

  // State to keep track of editing
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // * Local Storage Initialization ---------------------------
  // Set userCards state & duplicate to local storage

  const [userCards, setUserCards] = useState(() => {
    const savedCards: string | null = localStorage.getItem("cards");
    let initialValue: SpellCardInfo[] = [];
    if (typeof savedCards === "string") {
      initialValue = JSON.parse(savedCards);
    }
    return initialValue;
  });

  // Duplicate nextID to local storage
  const [nextId, setNextID] = useState<number>(() => {
    const savedNextId = localStorage.getItem("nextid");
    let initialValue: number = 0;
    if (typeof savedNextId === "string") {
      initialValue = JSON.parse(savedNextId);
    }
    return initialValue;
  });

  // Update Storage
  useEffect(() => {
    //Store data
    console.log("Updating localStorage...");
    localStorage.setItem("cards", JSON.stringify(userCards));
    localStorage.setItem("nextid", JSON.stringify(nextId));
  }, [userCards, nextId]);

  // Create database
  useEffect(() => {
    const createDB = async () => {
      try {
        const status = await initDB();
        console.log("IndexedDB created: " + status);
      } catch (error) {
        console.log(error);
      }
    };
    createDB();
  }, []);
  // ! The blank dependency makes this only load once when the app mounts

  // * Duplicate User Cards to Spellbook
  const [spellbook, setSpellbook] = useState([...userCards]);

  //Update Spellbook when userCards changes
  useEffect(() => {
    console.log("Setting Spellbook...");
    console.log(userCards);
    setSpellbook(JSON.parse(JSON.stringify(userCards)));
  }, [userCards]);

  // * App Functions ----------------------------------------------
  /**
   * Toggle state of the CardCreator.
   * @var isCollapse
   */
  const toggleCreator = () => {
    if (isEditing && !isCollapse) {
      if (window.confirm("Are you sure you'd like to stop editing?")) {
        setIsEditing(false);
        setCollapse(!isCollapse);
      } else {
        console.log("Cancelled");
      }
    } else {
      setCollapse(!isCollapse);
    }
  };

  // State for CardIndex
  const [showIndex, setShowIndex] = useState(
    window.innerWidth < 768 ? false : true,
  );

  /**
   * Toggle state of the CardCreator.
   */
  const toggleIndex = () => {
    setShowIndex(!showIndex);
  };

  const [showInfoModal, setShowInfoModal] = useState(false);

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  /**
   * Reset fields in card creator
   */
  const resetFields = () => {
    console.log("Fields Reset!");
    //Reset Field State Values
    dispatch({ type: FORM_ACTIONS.RESET });
  };

  /**
   * Gets number form card ID string
   * @param {string} id - element's id
   * @param {string} splitChar - Character used to split the string
   * @returns {number} Integer value of card ID
   * @example
   * //id = "cardindex-0"
   * parseCardId(id,"-");
   * //returns 0
   */
  function parseCardId(id: string, splitChar: string): number | undefined {
    const idString: string | undefined = id.split(`${splitChar}`).pop();
    if (idString !== undefined) {
      return parseInt(idString);
    } else {
      console.error("Unable to convert card id into a number");
    }
  }

  /**
   * Submit inputs and create a card
   * @param {*} event - Submit event
   */
  const createCard = async (event: React.FormEvent<HTMLFormElement>) => {
    //Prevent from submitting form
    event.preventDefault();

    //console.log("createCard is being run...");

    //Check if isEditing
    if (isEditing === true) {
      console.log("Updating card ...");
      // Create copy of userCards
      const editedUserCards = [...userCards];

      // Check if image has been changed
      let editedCard = editedUserCards.find(
        (card) => fieldStates.id === card.id,
      );

      // Add image to indexedDB BEFORE updating the userCards
      if (
        editedCard !== undefined &&
        fieldStates.spellimg.value !== editedCard.spellimg.value &&
        typeof fieldStates.spellimg.value === "string"
      ) {
        //Add image to imageStorage
        await addImage(fieldStates.id, fieldStates.spellimg.value);
      } else {
        setHasUpdate(true);
      }

      //Replace card at the current id
      editedUserCards.map((card) => {
        console.log(fieldStates.id, card.id);
        if (fieldStates.id === card.id) {
          Object.assign(card, {
            ...fieldStates,
            spellimg: { ...fieldStates.spellimg, value: fieldStates.id },
          });
          //console.log("The card has been replaced");
        }
        return card;
      });

      //Set Cards
      setUserCards(editedUserCards);

      //Turn off edit mode
      setIsEditing(!isEditing);
    } else {
      // Is NOT Editing, proceed with normal card creation

      // If and image was set
      if (
        typeof fieldStates.spellimg.value === "string" &&
        fieldStates.spellimg.value !== "none"
      ) {
        //set value of image
        await addImage(nextId, fieldStates.spellimg.value);

        // set user card
        setUserCards([
          {
            ...fieldStates,
            id: nextId,
            zindex: nextId + 1,
            spellimg: { ...fieldStates.spellimg, value: nextId },
          },
          ...userCards,
        ]);
      } else {
        await setHasUpdate(true);

        // set user card
        setUserCards([
          {
            ...fieldStates,
            id: nextId,
            zindex: nextId + 1,
          },
          ...userCards,
        ]);
      }

      setNextID(nextId + 1);
    }

    //Reset Input Fields
    resetFields();

    // If on Mobile, close the creator
    if (window.innerWidth < 768) {
      setCollapse(!isCollapse);
    }
  };

  /**
   * Activates edit mode for selecte card
   * @param {string} id - ID of the card being editted
   */
  const editCard = (id: string) => {
    console.log("Editing Card...");

    //Copy selected card to helper variable
    const editedCard = spellbook.find(
      (item) => item.id === parseCardId(id, "_"),
    );

    //Turn On "Edit Mode"
    setIsEditing(true);

    //Set New Card and Inputs to selected Card but remove the extra object keys?? or maybe keep the id?
    console.log(editedCard);
    dispatch({ type: FORM_ACTIONS.SET_FIELDS, payload: editedCard });

    //Open Card Creator
    setCollapse(false);

    //Close Spellbook on smaller screens
    if (window.innerWidth < 768) {
      setShowIndex(false);
    }
  };

  /**
   * Cancels editting mode
   */
  const cancelEdit = () => {
    setIsEditing(!isEditing);
    resetFields();
    setCollapse(!isCollapse);
  };

  /**
   * Duplicates selected card
   * @param {string} id - Element id of the card being copied
   * @param {number} order - The order of the card in the list
   */
  const copyCard = async (id: string, order: number) => {
    console.log("Copying card...");
    console.log("Card id =", id);
    // Get current id as a number
    const currentId: number | undefined = parseCardId(id, "_");

    //Shallow copy of current nextId
    const copiedCardId: number = nextId;

    const copyOfUserCards = [...userCards];

    //Create new entry in IDB with the same image
    if (currentId !== undefined) {
      // Copy card
      let copiedCard: SpellCardInfo | undefined = copyOfUserCards.find(
        (item) => item.id === currentId,
      );

      if (copiedCard !== undefined) {
        //If the card does have an image, update database first
        if (copiedCard.spellimg.value !== "none") {
          await copyImage(currentId, copiedCardId);

          copiedCard = {
            ...copiedCard,
            id: copiedCardId,
            spellname: {
              ...copiedCard.spellname,
              value: `${copiedCard.spellname.value}*`,
            },
            spellimg: {
              ...copiedCard.spellimg,
              value: copiedCardId,
            },
          };
        }
        // If it does NOT have an image, don't change the value of spellimg
        else {
          await setHasUpdate(true);

          copiedCard = {
            ...copiedCard,
            id: copiedCardId,
            spellname: {
              ...copiedCard.spellname,
              value: `${copiedCard.spellname.value}*`,
            },
          };
        }

        console.log("Copied card:", copiedCard);

        console.log("The ID of the card copied is: " + copiedCard.id);

        setNextID(nextId + 1);

        console.log("User Cards: ", userCards);

        setUserCards([
          ...userCards.slice(0, order + 1),
          copiedCard,
          ...userCards.slice(order + 1),
        ]);
      } else {
        console.error("copiedCard is undefined");
      }
    }
  };

  /**
   * Deletes selected card
   * @param {string} id - Element id of the card being copied
   * @param {string} splitChar - split char for target element's id
   */
  const deleteCard = async (id: string, splitChar: string) => {
    console.log("Deleting card ...");
    //console.log("parseCardId(id,splitChar)");

    //Remove image associated with card from IndexedDB
    const cardId = parseCardId(id, splitChar);
    console.log(cardId);

    if (cardId !== undefined) {
      console.log("awaiting deleteImage");
      await deleteImage(cardId);
      console.log("deleteImage complete");
    }

    //Remove card at index of card
    setUserCards(userCards.filter((a) => a.id !== cardId));
    // Spellbook is updated after this
  };

  /**
   * Deletes all cards from userCards/localStorage
   */
  const clearCards = async () => {
    //Reset IndexedDB
    await deleteAll();
    //Reset user cards
    setUserCards([]);
    //Reset id
    setNextID(0);
    //Reset localStorage
    localStorage.removeItem("cards");

    console.log("Cards Cleared");
  };

  // * useRef() stuff to highlight selected index - still under construction
  const focusRef = useRef<HTMLDivElement>(null);

  const [focusedCard, setFocusedCard] = useState<number>();

  const focusCard = (id: number) => {
    //console.log("Focussing on card " + id);
    setFocusedCard(id);

    const cardTable = focusRef.current;
    //console.log(cardTable);
    if (cardTable !== null) {
      const focusedSpellCard = cardTable.querySelector(`[data-id="${id}"]`);
      //console.log("Focused Spell Card:", focusedSpellCard);
      if (focusedSpellCard !== null) {
        focusedSpellCard.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      } else {
        console.warn(`No elements were found matching [data-id="${id}"]`);
      }
    } else {
      console.error("focusRef.current is not an element");
    }
  };

  const [sort, setSort] = useState<string>("");

  function raiseCard(id: number) {
    //console.log("the card selected was: " + id);

    //Create shallow copy of spellbook
    let orderedSpells: SpellCardInfo[] = [...spellbook];
    //console.log("Spells have been copied:");

    //let topCard = orderedSpells.find((spell) => spell.id === cardId);
    //let topCard = orderedSpells.find((spell) => spell.id === id).zindex;

    //console.log(topCard);

    orderedSpells = orderedSpells.map((card) => {
      const newCard: SpellCardInfo = { ...card };
      if (card.id === id) {
        newCard.zindex = spellbook.length;
      } else {
        if (newCard.zindex !== undefined) {
          newCard.zindex -= 1;
        } else {
          console.error("z-index is undefined");
        }
      }
      return newCard;
    });
    console.log(orderedSpells);

    setSpellbook(orderedSpells);
  }

  // * Image retrieval functions -----------------------------

  /**
   * Retrieves the spell card's image from the database
   * @param card
   * @returns
   */
  async function retrieveImage(card: SpellCardInfo) {
    try {
      if (typeof card.spellimg.value === "number") {
        const imageData: string = await getImage(card.spellimg.value);
        if (imageData !== undefined) {
          console.log(typeof imageData);
          console.log("Image data retrieved");
          return imageData;
        } else {
          console.error("No image data found");
        }
      }
    } catch (err) {
      console.error("There has been an error retrieving the image:", err);
    }
  }

  // useEffect to update all the images when the spellbook is updated
  useEffect(() => {
    console.log("useEffect() => retrieveImage()");
    let updatedSpellBook: SpellCardInfo[] = [...spellbook];

    /**
     * Updates the images in the spellbook whenever an image has been added/modified
     * @returns {void}
     */
    const retrieveImages = async () => {
      console.log("... Retrieving images");
      let hasUpdate = await checkHasUpdate();

      if (hasUpdate === true) {
        console.log("Database has been updated...");
        updatedSpellBook = await Promise.all(
          updatedSpellBook.map(async (card) => {
            if (card.id === -1 && typeof card.spellimg.value !== "number") {
              //console.log("This is the preview card");
              const image = card.spellimg.value;
              card.spellimg.value = image;

              return card;
            } else if (card.id === -1) {
              //console.log("Retrieving image for preview (card " + card.id + ")");
              const image = await retrieveImage(card);
              if (image !== undefined) {
                card.spellimg.value = image;
              } else {
                console.error("No image was found");
              }

              return card;
            } else if (typeof card.spellimg.value === "number") {
              console.log("Retrieving image for card " + card.id);
              const image = await retrieveImage(card);
              if (image !== undefined) {
                console.log(typeof image);
                card.spellimg.value = image;
              } else {
                console.error("Image is undefined");
              }
              return card;
            } else {
              console.log(typeof card.spellimg.value);
              console.log("No image to retrieve");

              return card;
            }
          }),
        );
        // Update the spellbook
        console.log("Spellbook has changed");
        //console.log(updatedSpellBook);
        setSpellbook([...updatedSpellBook]);

        // Tell the database that the cards have been changed
        await setHasUpdate(false);
      } else {
        console.log("No changes to database made - spells not updated");
      }
    };

    retrieveImages();
  }, [spellbook]);

  return (
    <div
      className="flex h-dvh flex-col items-center md:h-fit md:flex-row md:items-start md:justify-between print:!block print:break-inside-avoid print:overflow-visible"
      data-print="true"
    >
      <motion.div
        className="absolute top-0 z-30 flex w-full flex-col md:relative md:w-auto md:flex-row"
        data-print="false"
      >
        <LayoutGroup id="layoutgroup">
          <motion.div
            id="card-creator"
            layout="position"
            layoutRoot
            transition={{ layout: { duration: 0.5, damping: 300 } }}
            className={
              isCollapse
                ? "group relative z-30 flex h-fit w-full justify-start !overflow-hidden bg-black px-8 py-6 font-sans text-white *:hidden md:h-screen md:!w-fit md:px-16 md:py-16"
                : "group relative z-30 mb-4 flex h-dvh w-full shrink-0 flex-col items-start justify-start gap-6 overflow-y-hidden bg-black px-8 py-6 font-sans text-white md:mb-auto md:h-screen md:w-auto md:resize-x md:overflow-y-scroll md:px-16 md:py-16"
            }
            data-open={isCollapse ? "false" : "true"}
          >
            {/** Card Creator Header */}
            <motion.div
              id="card-creator-header"
              className={
                isCollapse
                  ? "!flex grow flex-row-reverse !justify-between self-stretch md:flex-col"
                  : "!flex !justify-end self-stretch"
              }
            >
              <motion.h1
                className={
                  isCollapse
                    ? "hidden flex-1 text-wrap font-sans text-3xl font-bold text-white opacity-80"
                    : "flex-1 text-wrap font-sans text-3xl font-bold text-white md:text-4xl"
                }
              >
                Spell Card Wizard &#129668;
              </motion.h1>
              <motion.button
                id="collapse-button"
                className={
                  isCollapse
                    ? "material-symbols-outlined focus-visible:outline-3 !block !rotate-45 text-4xl transition-transform focus-visible:text-blue focus-visible:outline-blue active:text-blue"
                    : "material-symbols-outlined focus-visible:outline-3 !block text-4xl transition-transform focus-visible:text-blue focus-visible:outline-blue active:text-blue"
                }
                tabIndex={0}
                onClick={toggleCreator}
              >
                close
              </motion.button>

              {isCollapse ? (
                <Button
                  type={ButtonType.None}
                  icon="help"
                  size="4xl"
                  handler={toggleInfoModal}
                />
              ) : null}
            </motion.div>
            {/** Card Creator Form*/}
            <Form
              createCard={createCard}
              isEditing={isEditing}
              fieldState={fieldStates}
              dispatch={dispatch}
            />
          </motion.div>

          {/*Card Preview*/}
          <div
            id="card-preview-wrapper"
            className="relative hidden w-0 md:flex"
          >
            <AnimatePresence>
              {isCollapse ? null : (
                <motion.div
                  id="card-preview-modal"
                  className="sticky z-20 mt-5 flex max-h-[90%] w-screen flex-col items-center gap-6 self-center rounded-md bg-white/50 px-20 pb-20 pt-10 shadow-xl backdrop-blur-md md:ml-10 md:w-auto"
                  key="preview-modal"
                  initial={{ x: -1000 }}
                  animate={{ x: 0 }}
                  exit={{ x: -1000 }}
                  transition={{ duration: 0.2 }}
                >
                  <div
                    id="preview-title"
                    className="flex flex-col items-center gap-2"
                  >
                    <p
                      id="preview-text"
                      className={
                        isEditing
                          ? "animate-pulse font-sans text-3xl font-bold text-black"
                          : "font-sans text-3xl font-bold text-black"
                      }
                    >
                      {isEditing ? "Editing" : "New Card"}
                    </p>
                    <button
                      id="cancel-edit-button"
                      className={
                        isEditing
                          ? "font-sans underline hover:text-purple-dark focus-visible:bg-purple active:bg-purple disabled:opacity-50"
                          : "hidden"
                      }
                      aria-label="cancel edit"
                      tabIndex={0}
                      onClick={cancelEdit}
                    >
                      [Cancel]
                    </button>
                  </div>
                  <div className="pointer-events-none contents">
                    <SpellCard id={-1} context={fieldStates} isFocus={false} />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </LayoutGroup>
      </motion.div>
      {showInfoModal ? <InfoModal toggleModal={toggleInfoModal} /> : null}

      {/*Card Table*/}
      <div
        id="card-table"
        className="fixed flex h-full min-h-fit w-full min-w-0 snap-x snap-mandatory flex-row gap-4 overflow-y-hidden overflow-x-scroll px-10 pb-48 pt-20 md:h-screen md:flex-1 md:snap-none md:flex-wrap md:items-center md:justify-center md:self-stretch md:overflow-x-hidden md:overflow-y-scroll md:py-20 md:pl-40 md:pr-24 md:data-[index='open']:pr-72 print:relative print:m-0 print:!block print:w-full print:items-start print:justify-start print:overflow-x-visible print:overflow-y-visible print:p-2"
        //ref={scrollRef}
        data-print="true"
        data-creator={isCollapse ? "close" : "open"}
        data-index={showIndex ? "open" : "close"}
        ref={focusRef}
      >
        {/*Card Table ==================================*/}
        <DndContext
          data-print="true"
          onDragStart={(e) => {
            if (typeof e.active.id === "string") {
              let cardId: string | undefined = e.active.id;
              cardId = cardId.split("-").pop();
              if (typeof cardId === "string") {
                raiseCard(parseInt(cardId));
              } else {
                console.error("Could not parse the card id into a number");
              }
            }
          }}
          /*onDragEnd={(e) => {
              e.over && e.over.id === "trash"
                ? deleteCard(e.active.id, "-")
                : console.log(null);
              cardtable.classList.remove("flex");
            }}*/
        >
          {spellbook?.map((card) => {
            return (
              <Draggable
                key={card.id}
                id={`${card.id}`}
                sort={sort}
                zindex={card.zindex}
                isFocus={focusedCard === card.id ? true : false}
              >
                <SpellCard id={card.id} context={card} />
              </Draggable>
            );
          })}
        </DndContext>
      </div>
      {/*Spellbook*/}
      <SpellBook
        userCards={userCards}
        showIndex={showIndex}
        toggleIndex={toggleIndex}
        setSort={setSort}
        spellbook={spellbook}
        setSpellbook={setSpellbook}
        clearCards={clearCards}
        editCard={editCard}
        copyCard={copyCard}
        deleteCard={deleteCard}
        isEditing={isEditing}
        raiseCard={raiseCard}
        focusCard={focusCard}
      />
    </div>
  );
}
export default SpellCardWizard;
