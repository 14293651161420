import { useState } from "react";
import { FORM_ACTIONS } from "./formActions";
import { SpellCardInfo } from "./formReducer";

export enum FieldTypes {
  Text = "TEXT",
  Number = "NUMBER",
  Select = "SELECT",
  TextArea = "TEXTAREA",
  InputSelect = "INPUTSELECT",
  MultiSelect = "MULTISELECT",
  CheckBox = "CHECKBOX",
  Color = "COLOR",
  File = "FILE",
}

interface FieldProps {
  type: FieldTypes;
  fieldid: string;
  fieldState: SpellCardInfo;
  handler: Function;
  icon?: string[];
  label?: string;
  size?: string;
  fieldtitle?: string;
  min?: number;
  max?: number;
  options?: string[];
  subtype?: FieldTypes;
}

// Tailwind Style variables
// *const inputStyleBase =
//   "h-11 rounded-md border border-white bg-black p-1 text-xl caret-blue-dark focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-fit md:text-md-rem ";
// *const toggleMixin = "hidden peer-has-[:checked]:block";

export function Field(props: FieldProps) {
  //let fieldState = props.fieldid;
  //let fieldStates = props.fieldStates;
  //let newFieldStates = { ...fieldStates }
  const { fieldState, fieldid, type } = props;
  const isOptional: boolean = fieldState[fieldid].optional;

  let inputs = <></>;

  switch (type) {
    case "NUMBER":
      inputs = <NumberInput {...props} />;
      break;
    case "TEXTAREA":
      inputs = <TextAreaInput {...props} />;
      break;
    case "SELECT":
      inputs = <SelectInput {...props} />;
      break;
    case "INPUTSELECT":
      inputs = <InputSelectInput {...props} />;
      break;
    case "MULTISELECT":
      inputs = <MultiSelectInput {...props} />;
      break;
    case "CHECKBOX":
      inputs = <CheckboxInput {...props} />;
      break;
    case "COLOR":
      inputs = <ColorInput {...props} />;
      break;
    case "FILE":
      inputs = <FileInput {...props} />;
      break;
    case "TEXT":
      inputs = <TextInput {...props} />;
      break;
    default:
      inputs = <TextInput {...props} />;
  }

  return (
    <div
      id={`${fieldid}-field`}
      className={
        type === "CHECKBOX" || type === "FILE" || type === "COLOR"
          ? "flex w-full items-center justify-between md:gap-4"
          : "flex w-full flex-col items-start justify-start gap-1"
      }
    >
      <div
        id={`${fieldid}-field-label`}
        className={
          isOptional
            ? "group/label peer flex items-center justify-between self-stretch"
            : "group/label peer flex w-fit items-center justify-between"
        }
      >
        <FieldLabel {...props} />
        <FieldToggle {...props} />
      </div>
      {inputs}
    </div>
  );
}

/**
 * ### Controls whether field is included or not
 * ---
 * @param {*} props
 * @returns JSXElement and fieldState
 */
function FieldToggle(props: FieldProps) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    props.handler({
      type: FORM_ACTIONS.SET_VIS,
      field: { name: props.fieldid },
    });
    console.log(props.fieldid + " has been clicked");
    console.log(props.fieldState[props.fieldid]["visibility"]);
  };

  // TODO: Add handler for pressing space on checkbox to toggle

  if (props.fieldState[props.fieldid]["optional"] === true) {
    return (
      <>
        <input
          id={`checkbox-${props.fieldid}`}
          className="peer/checkbox hidden focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
          type="checkbox"
          name={props.fieldid}
          value={props.fieldState[props.fieldid]["visibility"]}
          checked={props.fieldState[props.fieldid]["visibility"]}
          onChange={handleCheckbox}
        />
        <label
          className="material-symbols-outlined color-white flex-1 cursor-pointer text-right text-5xl font-bold opacity-50 before:content-['add'] peer-checked/checkbox:opacity-100 peer-checked/checkbox:before:content-['remove'] md:text-2xl"
          htmlFor={`checkbox-${props.fieldid}`}
          tabIndex={0}
        ></label>
      </>
    );
  } else {
    return (
      <input
        id={`checkbox-${props.fieldid}`}
        className="peer/checkbox hidden focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
        type="checkbox"
        name={props.fieldid}
        value={props.fieldState[props.fieldid]["visibility"]}
        checked={props.fieldState[props.fieldid]["visibility"]}
        readOnly
      />
    );
  }
}

/**
 * ### Label for <Field/> Element
 * ---
 * @param {FieldProps} props
 * @returns {JSX.Element}
 */
function FieldLabel(props: FieldProps): JSX.Element {
  return (
    <label
      className={
        "min-w-24 shrink text-base font-semibold opacity-50 group-has-[:checked]/label:opacity-100 md:inline-block md:min-w-36 md:py-0 md:text-base"
      }
    >
      {props.fieldtitle}:
    </label>
  );
}

// function InputSet(props: { children: JSX.Element }) {
//   return <div className="flex gap-2">{props.children}</div>;
// }

function FieldIcon(props: { icon: string | undefined }) {
  const { icon } = props;
  if (icon) {
    return (
      <span
        id={icon}
        className="material-symbols-outlined absolute left-4 top-4 text-white/30 md:left-2 md:top-2"
        aria-hidden={true}
      >
        {icon}
      </span>
    );
  }
}

const inputStyle =
  "relative hidden min-w-0 flex-1 basis-0 items-center self-stretch peer-has-[:checked]:flex";

function TextInput(props: FieldProps) {
  const { fieldid, fieldState, icon, handler } = props;
  return (
    <div className="relative hidden min-w-0 flex-1 basis-0 items-center self-stretch peer-has-[:checked]:flex">
      <FieldIcon icon={icon ? icon[0] : undefined} />
      <input
        name={fieldid}
        value={fieldState[fieldid]["value"]}
        tabIndex={0}
        className="flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black bg-transparent p-3 pl-12 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
        type="text"
        onChange={(e) =>
          handler({
            type: FORM_ACTIONS.SET_INPUT,
            field: { name: fieldid, value: e.target.value },
          })
        }
      ></input>
    </div>
  );
}

function NumberInput(props: FieldProps) {
  const { fieldid, fieldState, icon, handler, min, max } = props;

  return (
    <div className={inputStyle}>
      <FieldIcon icon={icon ? icon[0] : undefined} />
      <input
        name={fieldid}
        value={fieldState[fieldid]["value"]}
        tabIndex={0}
        className={`'flex-1 'gap-8 self-stretch truncate rounded border border-white/30 bg-black bg-transparent p-3 pl-12 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:max-w-52 md:flex-auto md:py-2 md:pl-10 md:pr-2 md:text-md-rem`}
        type="number"
        min={min}
        max={max}
        onChange={(e) =>
          handler({
            type: FORM_ACTIONS.SET_INPUT,
            field: { name: fieldid, value: e.target.value },
          })
        }
      ></input>
    </div>
  );
}

/**
 * ### textarea input
 * ---
 * @param {*} props
 * @returns JSX Element
 */
function TextAreaInput(props: FieldProps) {
  const { fieldid, fieldState, handler } = props;
  return (
    <textarea
      name={fieldid}
      value={fieldState[fieldid]["value"]}
      className={
        "hidden h-36 w-full min-w-0 self-stretch rounded border border-white/30 bg-black p-3 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue peer-has-[:checked]:block md:h-auto md:p-2 md:text-md-rem"
      }
      tabIndex={0}
      rows={window.innerWidth < 768 ? 1 : undefined}
      onChange={(e) =>
        handler({
          type: FORM_ACTIONS.SET_INPUT,
          field: { name: fieldid, value: e.target.value },
        })
      }
    ></textarea>
  );
}

function SelectInput(props: FieldProps) {
  const { fieldid, icon, fieldState, options, handler } = props;
  return (
    <div className={inputStyle}>
      <FieldIcon icon={icon ? icon[0] : undefined} />
      <select
        name={fieldid}
        value={fieldState[fieldid]["value"]}
        className="flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black p-3 pl-12 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
        tabIndex={0}
        onChange={(e) =>
          handler({
            type: FORM_ACTIONS.SET_INPUT,
            field: { name: fieldid, value: e.target.value },
          })
        }
      >
        <option className="bg-black text-white/30" value="">
          ---
        </option>
        {options?.map((item, index) => {
          return (
            <option className="bg-black" key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
}

function ColorInput(props: FieldProps) {
  return (
    <input
      name={props.fieldid}
      value={props.fieldState[`${props.fieldid}`]["value"]}
      tabIndex={0}
      className="h-16 flex-1 bg-black focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
      type={props.type}
      min={props.min}
      max={props.max}
      onChange={(e) =>
        props.handler({
          type: FORM_ACTIONS.SET_COLOR,
          field: { name: props.fieldid, value: e.target.value },
        })
      }
    ></input>
  );
}

function FileInput(props: FieldProps) {
  return (
    <input
      name={props.fieldid}
      type="file"
      accept="image/*"
      tabIndex={0}
      className="min-w-0 flex-1 flex-col text-white/60 file:flex file:h-12 file:w-full file:rounded file:bg-white file:font-bold file:before:content-['d']"
      onChange={(e) => {
        let image = e.target.files ? e.target.files[0] : undefined;
        let reader = new FileReader();
        let imageData;
        reader.addEventListener(
          "load",
          () => {
            // convert image file to base64 string
            imageData = reader.result;
            //console.log(reader.result);

            // update form state
            props.handler({
              type: FORM_ACTIONS.SET_IMAGE,
              field: { name: props.fieldid, value: imageData },
            });
          },
          false,
        );

        if (image === undefined) {
          alert("Sorry, couldn't read the image");
        } else if (image.size > 1048576) {
          alert(
            "Sorry, this file is too big. Please upload an image under 1MB",
          );
        } else {
          reader.readAsDataURL(image);
        }
      }}
    ></input>
  );
}

function CheckboxInput(props: FieldProps) {
  const { icon, fieldid, fieldState, handler } = props;
  return (
    <div className="relative flex flex-1 items-center justify-end gap-2">
      <label
        className="material-symbols-outlined text-white/30 peer-checked:text-white"
        aria-hidden
      >
        {icon ? icon[0] : undefined}
      </label>
      <input
        name={`${fieldid}-field-checkbox`}
        id={`${fieldid}-checkbox`}
        value={fieldState[fieldid]["value"]}
        checked={fieldState[fieldid]["value"]}
        tabIndex={0}
        className="peer h-12 w-12 appearance-auto justify-start rounded border-2 border-white bg-black p-1 font-sans text-black checked:accent-white focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
        type="checkbox"
        onChange={(e) => {
          handler({
            type: FORM_ACTIONS.SET_CHECKBOX,
            field: { name: fieldid },
          });
        }}
      ></input>
    </div>
  );
}

/**
 * ### Input field for Spell Components
 * ---
 * @param {*} props
 * @returns JSXElement
 */
function MultiSelectInput(props: FieldProps) {
  // let shortNames = props.options?.map((name) => {
  //   return name.charAt(0).toUpperCase();
  // });

  const { fieldid, fieldState, icon, options, handler } = props;

  return (
    <div className="flex h-12 flex-1 flex-wrap gap-4 self-stretch px-4 md:px-0">
      {options?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex h-fit w-fit cursor-pointer items-center gap-2 rounded-full border border-white/60 py-2 pl-4 pr-4 text-xl has-[:checked]:bg-white has-[:checked]:pr-3 has-[:checked]:font-medium has-[:checked]:text-black md:text-md-rem"
            tabIndex={0}
          >
            <span className="material-symbols-outlined">
              {icon ? icon[index] : undefined}
            </span>
            <label
              className="min-w-0 cursor-pointer outline-blue md:w-fit md:border-none md:text-left"
              htmlFor={`multi-select-checkbox${index}`}
            >
              {item}
            </label>
            <input
              id={`multi-select-checkbox${index}`}
              name={`multi-select-checkbox${index}`}
              type="checkbox"
              value={fieldState[`${fieldid}`].value[index]}
              checked={fieldState[`${fieldid}`].value[index]}
              tabIndex={-1}
              className="peer/checkbox hidden h-6 w-6 appearance-none justify-start rounded-full border border-black/5 bg-black p-1 text-black checked:block checked:accent-blue-dark focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
              onChange={() =>
                handler({
                  type: FORM_ACTIONS.SET_MULTISELECT,
                  field: { id: index },
                })
              }
            />
          </div>
        );
      })}
    </div>
  );
}

function InputSelectInput(props: FieldProps) {
  const { fieldid, subtype, fieldState, min, handler, options, icon } = props;
  return (
    <div className={`${inputStyle} min-w-0 gap-2`}>
      <div className="relative flex min-w-0 flex-1">
        <FieldIcon icon={icon ? icon[0] : undefined} />
        <input
          name={fieldid}
          type={subtype}
          value={fieldState[fieldid]["value"]["input"]}
          tabIndex={0}
          className="min-w-0 flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black bg-transparent p-3 text-xl caret-blue autofill:bg-black autofill:text-white focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
          min={min}
          placeholder="--"
          onChange={(e) =>
            handler({
              type: FORM_ACTIONS.SET_INPUTSELECT,
              field: {
                name: fieldid,
                input: "input",
                value: e.target.value,
              },
            })
          }
        ></input>
      </div>
      <div className="relative flex min-w-0 flex-1 basis-0">
        <FieldIcon icon={icon ? icon[1] : undefined} />
        <select
          name={`${fieldid[1]}Select`}
          value={fieldState[fieldid]["value"]["unit"]}
          tabIndex={0}
          className="min-w-0 flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black p-3 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
          onChange={(e) =>
            handler({
              type: FORM_ACTIONS.SET_INPUTSELECT,
              field: {
                name: fieldid,
                input: "select",
                value: e.target.value,
              },
            })
          }
        >
          <option className="bg-black" value="">
            --
          </option>
          {options?.map((option, index) => {
            return (
              <option
                className="bg-black"
                key={index}
                value={option.toLowerCase()}
              >
                {option}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
